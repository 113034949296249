import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/satisfacao/:ticketJwt',
    name: 'FormularioSatisfacao',
    component: () => import('../views/FormularioSatisfacao.vue')
  },
  {
    path: '/',
    name: 'FormularioPosVendas',
    component: () => import('../views/FormularioPosVendas.vue')
  }
]

const router = new VueRouter({
  routes
})

export default router
