import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary:  '#350061',
                secondary: '#FF6101',
                accent:  '#3F51B5'
            },
        },
    },
});
